import styled from 'styled-components';
import Button from './Button';

const ButtonOutline = styled(Button)`
  background: transparent;
  border-style: solid;
  border-width: 2px;
  border-color: var(--secondary);
  color: var(--secondary);
  :hover {
    background: var(--secondary);
    color: white;
  }
`;

export default ButtonOutline;
