import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Inner from '../components/Styles/Inner';

import ButtonBase from '../components/Buttons/ButtonBase';
import Button from '../components/Buttons/Button';
import ButtonSecondary from '../components/Buttons/ButtonSecondary';
import ButtonGradient from '../components/Buttons/ButtonGradient';
import ButtonText from '../components/Buttons/ButtonText';
import ButtonOutline from '../components/Buttons/ButtonOutline';

import Tabs from '../components/Tabs';
import ComplexTabs from '../components/ComplexTabs';

const StyleGuide = () => (
  <Layout>
    <SEO title="ALTdev Style Guide" />
    <Inner>
      <h1>ALTdev Style Guide</h1>
      <h2>Buttons</h2>
      <ButtonBase>Button Base</ButtonBase>
      <Button>Button Primary</Button>
      <ButtonSecondary>Button Secondary</ButtonSecondary>
      <ButtonGradient>Button Gradient</ButtonGradient>
      <ButtonText>Button Text</ButtonText>
      <ButtonOutline>Button Outline</ButtonOutline>
      <br />
      <hr />
      <br />
      <Tabs />
      <ComplexTabs />
    </Inner>
  </Layout>
);

export default StyleGuide;
